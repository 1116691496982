import fm from 'format-message';
import PropTypes from 'prop-types';
import media from '@activebrands/core-web/config/media';
import { TrackingLists } from '@activebrands/core-web/libs/Tracking/constants';
import useReInitWidgets from '@activebrands/core-web/libs/lipscore/hooks/useReInitWidgets';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import OverlayHeader from 'components/overlays/OverlayHeader';
import ProductCard from 'components/product/ProductCard';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    background: 'var(--color-base-white)',
    position: 'relative',
    zIndex: 0,
    width: 'var(--width-overlays)',
    height: 'auto',
    overflowY: 'hidden',

    [media.min['tablet.sm']]: {
        height: 'var(--height-overlay)',
    },
});

const ProductsGrid = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    gap: '32px 12px',
    marginBottom: '24px',

    [media.min['tablet.sm']]: {
        flexDirection: 'column',
        marginBottom: 'calc(var(--height-header) + 8px + 24px)', // 8px is for the gap between header and overlay
    },
});

const ProductsWrapper = styled('div', {
    display: 'flex',
    overflowY: 'hidden',
    padding: '0 12px',
    '-webkit-overflow-scrolling': 'touch',

    [media.min['tablet.sm']]: {
        display: 'block',
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '100%',
        padding: '0 24px',
    },
});

const Overlay = ({ products = [] }) => {
    const [css] = useStyletron();

    useReInitWidgets(products);

    return (
        <Wrapper>
            <OverlayHeader
                $style={{
                    borderBottom: '1px solid var(--color-base-brand-grey-light)',
                    padding: ['0 12px', null, null, '0 24px'],
                }}
                headingText={fm('Shop the look')}
                overlayId="shop-the-look"
            />
            <ProductsWrapper data-scrollbar={false}>
                <ProductsGrid>
                    {products?.length > 0 ? (
                        products.map((product, i) => (
                            <ProductCard
                                $style={{ width: ['80vw', null, '60vw', '100%'] }}
                                key={product.id}
                                {...product}
                                media={product.media?.length > 0 ? product.media.slice(0, 1) : []}
                                loading="lazy"
                                position={i}
                                trackingList={TrackingLists.SHOP_THE_LOOK}
                                onLinkClick={() => overlay.close('shop-the-look')}
                            />
                        ))
                    ) : (
                        <div className={css({ width: '100%', textAlign: 'center' })}>
                            <Paragraph>{fm('No products found...')}</Paragraph>
                        </div>
                    )}
                </ProductsGrid>
            </ProductsWrapper>
        </Wrapper>
    );
};

Overlay.propTypes = {
    products: PropTypes.array.isRequired,
};

export default Overlay;
